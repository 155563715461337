import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../../components/Dialogs/Dialog";
import { TextFieldWithLabel } from "../../components/TextFieldWithLabel/TextFieldWithLabel";
import { useDispatchWrapper } from "../../hooks";
import { upsertCountryAsync } from "../../redux/country/countryActions";
import { IManageCategoryProps } from "./ManageCategory.types";
import { upsertCategoryAsync } from "../../redux/HR/Category";

export const ManageCategory: React.FC<IManageCategoryProps> = (props) => {
  const { open, category, onComplete, onClose } = props;
  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: category,
      validate: (values) => {
        const errors: any = {};
        if (!values.category_name) {
          errors.category_name = "* Category name is required!";
        }
        return errors;
      },
      onSubmit: async (values) => {
        setLoading(true);
        dispatch(
          upsertCategoryAsync(values, (isSuccess, category) => {
            setLoading(false);
            if (isSuccess && category) {
              onComplete(category);
            }
          }),
        );
      },
    });

  return (
    <>
      <Dialog
        open={open}
        title="Manage Category"
        onClose={onClose}
        size="sm"
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "submit",
            label: "Save",
            variant: "contained",
            disabled: loading,
          },
        ]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextFieldWithLabel
              heading={"Category Name"}
              type="text"
              id="category_name"
              fullWidth
              value={values.category_name}
              error={errors.category_name ? true : false}
              helperText={errors.category_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldWithLabel
              heading={"Type"}
              type="text"
              id="type"
              fullWidth
              value={values.type}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldWithLabel
              rows={3}
              multiline
              heading={"Description"}
              type="text"
              id="description"
              fullWidth
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
