import { ActionType } from "typesafe-actions";

import * as JobAction from "./jobActions";

type Actions = typeof JobAction;

export type JobActions = ActionType<Actions>;

export * from "./jobActions";
export * from "./jobReducer";
export * from "./defaultState";
