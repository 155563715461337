import React from "react";
import { Box, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import { IQuillEditorProps } from "./QuillEditor.types";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image", "video"],
    [{ align: [] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  ],
};

const editorStyles = `
  .ql-editor {
    line-height: 1.5;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
`;

export const QuillEditor: React.FC<IQuillEditorProps> = (props) => {
  const { value, onChange } = props;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          position: "relative",
          minHeight: 200,
          overflow: "auto",
          ".ql-toolbar": {
            position: "sticky",
            top: 0,
            zIndex: 100,
            backgroundColor: "#fff",
          },
          ".ql-editor": {
            minHeight: 200,
            maxHeight: 400,
            overflowY: "auto",
            padding: "10px",
          },
        }}
      >
        <style>{editorStyles}</style>
        <ReactQuill
          placeholder="Start typing here..."
          modules={modules}
          theme="snow"
          value={value}
          onChange={onChange}
        />
      </Box>
    </Grid>
  );
};
