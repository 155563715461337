import { LoadState } from "../../constants/enums";
import { ILeads, ILeadState } from "./leads.types";

export const defaultLeads: ILeads = {
  calculator_user_uuid: null,
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  status: "ACTIVE",
  job_title: "",
  client_invoice_currency: "",
};

export const defaultleadState: ILeadState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  lead: {
    data: defaultLeads,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
