import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { ICompany } from "./company.types";
import { IQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { getSearchQuery } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { uploadFile } from "../../../helpers/uploadFile";

//=============================== Company List ===============================

export const FETCH_COMPANY_LIST_PROGRESS = "FETCH_COMPANY_LIST_PROGRESS";
export const FETCH_COMPANY_LIST_SUCCESS = "FETCH_COMPANY_LIST_SUCCESS";
export const FETCH_COMPANY_LIST_FAILED = "FETCH_COMPANY_LIST_FAILED";

export const fetchCompanyListProgress = () =>
  action(FETCH_COMPANY_LIST_PROGRESS);
export const fetchCompanyListSuccess = (
  data: ICompany[],
  totalRecords: number,
) => action(FETCH_COMPANY_LIST_SUCCESS, { data, totalRecords });
export const fetchCompanyListFailed = () => action(FETCH_COMPANY_LIST_FAILED);

export const fetchCompanyListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCompanyListProgress());
      const res = await api.get(`/hr/get-company${searchQuery}`);
      const data: ICompany[] = res.data.data;

      console.log(data);

      const totalRecords = res.data.totalRecords;

      console.log(data, "records");

      dispatch(fetchCompanyListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCompanyListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Company ===============================

export const upsertCompanyAsync =
  (
    data: ICompany,
    company_logo: File | null,
    onCallback: (isSuccess: boolean, Company?: ICompany) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    let { create_ts, insert_ts, ...rest } = data;

    try {
      dispatch(saveLoaderProgress());

      let logoPath = data.company_logo || "";

      if (company_logo) {
        logoPath = await uploadFile(
          company_logo,
          data.company_logo?.toString() || "",
        );
      }

      const res = await api.post("/hr/upsert-company", {
        ...rest,
        company_logo: logoPath,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Company saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message ||
            "Error occurred while saving Company",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
//*********************** fetching single data **********************//

export const FETCH_COMPANY_PROGRESS = "FETCH_COMPANY_PROGRESS";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILED = "FETCH_COMPANY_FAILED";

export const fetchCompanyProgress = () => action(FETCH_COMPANY_PROGRESS);
export const fetchCompanySuccess = (data: ICompany) =>
  action(FETCH_COMPANY_SUCCESS, { data });
export const fetchCompanyFailed = (errorMessage: string) =>
  action(FETCH_COMPANY_FAILED, { errorMessage });

export const fetchCompanyAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCompanyProgress());
      const res = await api.get(`/website/get-Company?Company_uuid=${uuid}`);
      const data: ICompany[] = res.data.data;
      console.log(data, "data");
      if (data.length > 0) {
        dispatch(fetchCompanySuccess(data[0]));
      } else {
        dispatch(fetchCompanyFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchCompanyFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";
export const clearCompany = () => action(CLEAR_COMPANY);
export const clearCompanyState = () => action(CLEAR_COMPANY_STATE);
