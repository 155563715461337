import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile } from "../../helpers/uploadFile";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAllApplication,
  IHrRequestFormDialog,
  IUserOffboarding,
  IUserOnboarding,
} from "./allApplications.types";

export const FETCH_APPLICATIONS_LIST_PROGRESS =
  "FETCH_APPLICATIONS_LIST_PROGRESS";
export const FETCH_APPLICATIONS_LIST_SUCCESS =
  "FETCH_APPLICATIONS_LIST_SUCCESS";
export const FETCH_APPLICATIONS_LIST_FAILED = "FETCH_APPLICATIONS_LIST_FAILED";

export const fetchApplicationsListProgress = () =>
  action(FETCH_APPLICATIONS_LIST_PROGRESS);
export const fetchApplicationsListSuccess = (
  list: IAllApplication[],
  totalRecords: number,
  searchValue: string,
) =>
  action(FETCH_APPLICATIONS_LIST_SUCCESS, { list, totalRecords, searchValue });
export const fetchApplicationsListFailed = () =>
  action(FETCH_APPLICATIONS_LIST_FAILED);

export const fetchApplicationsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    jobUuid: string,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    searchBy: string,
    searchByValue: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchApplicationsListProgress());

      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      if (jobUuid) {
        url = url + `&job_uuid=${jobUuid}`;
      }
      // Handle search parameters for company name, job title, and category
      if (searchBy !== "-1" && searchBy !== "reset" && searchByValue) {
        url = url + `&${searchBy}=${searchByValue}`;
      }

      let finalUrl = `/hr/get-application?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/hr/get-application?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}&columns=${searchType}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: IAllApplication[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchApplicationsListSuccess(data, totalRecords, searchValue));
    } catch (err: any) {
      dispatch(fetchApplicationsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_APPLICATION_PROGRESS = "FETCH_APPLICATION_PROGRESS";
export const FETCH_APPLICATION_SUCCESS = "FETCH_APPLICATION_SUCCESS";
export const FETCH_APPLICATION_FAILED = "FETCH_APPLICATION_FAILED";

export const fetchApplicationProgress = () =>
  action(FETCH_APPLICATION_PROGRESS);
export const fetchApplicationSuccess = (application: IAllApplication) =>
  action(FETCH_APPLICATION_SUCCESS, { application });
export const fetchApplicationFailed = (errorMessage: string) =>
  action(FETCH_APPLICATION_FAILED, { errorMessage });

export const fetchApplicationAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    application_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchApplicationProgress());

      const res = await api.get(
        `/hr/get-application?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&application_uuid=${application_uuid}`,
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchApplicationFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchApplicationFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertApplicationAysnc =
  (
    application: IAllApplication,

    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        created_by_uuid,
        modified_by_uuid,
        create_ts,
        shortlist_status,
        insert_ts,
        skills_match_percentage,
        additional_details,
        required_skills,
        applicant_skills,
        category_uuid,
        category_name,
        company_uuid,
        company_name,
        ...rest
      } = application;

      await api.post("/hr/upsert-application", {
        ...rest,
      });
      let message = "Application is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_APPLICATION = "CLEAR_APPLICATION";
export const clearApplication = () => action(CLEAR_APPLICATION);

export const FETCH_USER_OFF_BOARDING_PROGRESS =
  "FETCH_USER_OFF_BOARDING_PROGRESS";
export const FETCH_USER_OFF_BOARDING_SUCCESS =
  "FETCH_USER_OFF_BOARDING_SUCCESS";
export const FETCH_USER_OFF_BOARDING_FAILED = "FETCH_USER_OFF_BOARDING_FAILED";

export const fetchUserOffBoardingProgress = () =>
  action(FETCH_USER_OFF_BOARDING_PROGRESS);
export const fetchUserOffBoardingSuccess = (
  userOffBoarding: IUserOffboarding,
) => action(FETCH_USER_OFF_BOARDING_SUCCESS, { userOffBoarding });
export const fetchUserOffBoardingFailed = (errorMessage: string) =>
  action(FETCH_USER_OFF_BOARDING_FAILED, { errorMessage });

export const fetchUserOffBoardingAsync =
  (application_number: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserOffBoardingProgress());

      const res = await api.get(
        `/hr/get-user-offboarding?value=${application_number}`,
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUserOffBoardingSuccess(data[0]));
      } else {
        dispatch(
          fetchUserOffBoardingFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchUserOffBoardingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertUserOffBoardingAysnc =
  (
    userOffBoarding: IUserOffboarding,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/hr/edit-user-offboarding", userOffBoarding);
      let message = "User Off Boarded successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_USER_ON_BOARDING_PROGRESS =
  "FETCH_USER_ON_BOARDING_PROGRESS";
export const FETCH_USER_ON_BOARDING_SUCCESS = "FETCH_USER_ON_BOARDING_SUCCESS";
export const FETCH_USER_ON_BOARDING_FAILED = "FETCH_USER_ON_BOARDING_FAILED";

export const fetchUserOnBoardingProgress = () =>
  action(FETCH_USER_ON_BOARDING_PROGRESS);
export const fetchUserOnBoardingSuccess = (userOnBoarding: IUserOnboarding) =>
  action(FETCH_USER_ON_BOARDING_SUCCESS, { userOnBoarding });
export const fetchUserOnBoardingFailed = (errorMessage: string) =>
  action(FETCH_USER_ON_BOARDING_FAILED, { errorMessage });

export const fetchUserOnBoardingAsync =
  (application_number: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserOnBoardingProgress());

      const res = await api.get(
        `/hr/get-onboarding-checklist?application_number=${application_number}`,
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUserOnBoardingSuccess(data[0]));
      } else {
        dispatch(
          fetchUserOnBoardingFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchUserOnBoardingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertUserOnBoardingAysnc =
  (
    userOnBoarding: IUserOnboarding,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/hr/edit-onboarding-checklist", userOnBoarding);
      let message = "User On Boarded successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_REQUEST_FORM_LIST_PROGRESS =
  "FETCH_REQUEST_FORM_LIST_PROGRESS";
export const FETCH_REQUEST_FORM_LIST_SUCCESS =
  "FETCH_REQUEST_FORM_LIST_SUCCESS";
export const FETCH_REQUEST_FORM_LIST_FAILED = "FETCH_REQUEST_FORM_LIST_FAILED";

export const fetchRequestFormListProgress = () =>
  action(FETCH_REQUEST_FORM_LIST_PROGRESS);
export const fetchRequestFormListSuccess = (list: IHrRequestFormDialog[]) =>
  action(FETCH_REQUEST_FORM_LIST_SUCCESS, { list });
export const fetchRequestFormListFailed = (errorMessage: string) =>
  action(FETCH_REQUEST_FORM_LIST_FAILED, { errorMessage });

export const fetchRequestFormListAsync =
  (
    status: string,
    pageNumber: number,
    rowsInPerPage: number,
    searchValue: string,
    searchType: string[],
    date: IDate,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRequestFormListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/hr/get-user-request?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/hr/get-user-request?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const response = await api.get(finalUrl);
      const data = response.data.data;
      if (data) {
        dispatch(fetchRequestFormListSuccess(data));
      } else {
        dispatch(
          fetchRequestFormListFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchRequestFormListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchAddRequestFormAsync =
  (
    data: IHrRequestFormDialog,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post("/hr/edit-user-request", data);
      let message = "Request Form added successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
