import produce from "immer";
import { commonActions } from ".";
import { IStoreState } from "../initialStoreState";
import { defaultCommonState } from "./common.types";
import {
  CLEAR_GENERAL_HISTORY_STATE,
  CLEAR_RECORD_COUNTS,
  FETCH_GENERAL_HISTORY_LIST_FAILED,
  FETCH_GENERAL_HISTORY_LIST_PROGRESS,
  FETCH_GENERAL_HISTORY_LIST_SUCCESS,
  FETCH_POLCIES_FAILED,
  FETCH_POLICIES_PROGRESS,
  FETCH_POLICIES_SUCCESS,
  FETCH_RECORD_COUNTS_FAILED,
  FETCH_RECORD_COUNTS_PROGRESS,
  FETCH_RECORD_COUNTS_SUCCESS,
} from "./commonActions";
import { LoadState } from "../../constants/enums";

export const commonReducer = (
  state: IStoreState["common"] = defaultCommonState,
  action: commonActions,
) => {
  switch (action.type) {
    case FETCH_POLICIES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_POLICIES_SUCCESS: {
      const { policies } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.Loaded;
        draftState.policies.list = policies;
      });
      return newState;
    }
    case FETCH_POLCIES_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.Failed;
        draftState.policies.list = [];
      });
      return newState;
    }

    case FETCH_GENERAL_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.InProgress;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case FETCH_GENERAL_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.Loaded;
        draftState.generalHistory.list = data;
      });
      return newState;
    }
    case FETCH_GENERAL_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.Failed;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case CLEAR_GENERAL_HISTORY_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory = defaultCommonState["generalHistory"];
      });
      return newState;
    }

    case FETCH_RECORD_COUNTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.InProgress;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.recordCounts.loading = LoadState.Loaded;
        draftState.recordCounts.list = data;
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.recordCounts.loading = LoadState.Failed;
        draftState.recordCounts.list = [];
      });
      return newState;
    }
    case CLEAR_RECORD_COUNTS: {
      const newState = produce(state, (draftState) => {
        draftState.recordCounts = defaultCommonState["recordCounts"];
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
