/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";

import { PageLoader } from "../../components/PageLoader/PageLoader";
import { TextFieldWithLabel } from "../../components/TextFieldWithLabel/TextFieldWithLabel";

import { LoadState } from "../../constants/enums";
import { useDispatchWrapper } from "../../hooks";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";

import { IStoreState } from "../../redux/initialStoreState";

import { clearAssumption } from "../../redux/assumption";

import { CategoryAutoSearch } from "../../components/AutoCompleteSearches/CategoryAutoSearch";
import { CompanyAutoSearch } from "../../components/AutoCompleteSearches/CompanyAutoSearch";
import {
  CustomFormLabel,
  CustomSelect,
} from "../../components/formsComponents";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { OptionType } from "../../components/FreeSolo/FreeSolo";
import { ImageField } from "../../components/ImageField/ImageField";
import { QuillEditor } from "../../components/QuillEditor/QuillEditor";
import { ICategory } from "../../redux/HR/Category/category.types";
import { ICompany } from "../../redux/HR/Company/company.types";
import { fetchJobAsync, upsertJobAsync } from "../../redux/HR/Jobs";
import { IJob } from "../../redux/HR/Jobs/job.types";
import FreeSoloMultiplesCreatable from "../../components/FreeSolo/FreeSoloMultiple";

export const ManageJob = () => {
  const { uuid } = useParams() as {
    uuid?: string;
    comment?: string;
  };

  const isUpdate = uuid ? true : false;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = React.useState<File | null>(null);
  const [companyImage, setCompanyImage] = React.useState<File | null>(null);

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/job",
      title: "Job",
    },
    {
      title: isUpdate ? "Edit Job" : "Create Job",
    },
  ];

  const {
    data: job,
    loading: jobLoading,
    error,
  } = useSelector((storeState: IStoreState) => storeState.job.job);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [hasSpaces, setHasSpaces] = React.useState(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: job,
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: any = {};
      if (!values.company_name) {
        errors.company_name = "* Company name is required";
      } else {
        setHasSpaces(false);
      }

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        upsertJobAsync({ ...values }, companyLogo, null, (isSuccess: any) => {
          if (isSuccess) {
            navigate("/job");
          }
          setLoading(false);
        }),
      );
    },
  });

  console.log(errors, "errors");
  console.log(values, "values");

  const handleOptionChange = (value: OptionType | null) => {
    setFieldValue("languages", value ? value.title : "");
  };

  React.useEffect(() => {
    let finalJob: IJob = {
      ...job,
    };
    setValues(finalJob);
  }, [job]);

  React.useEffect(() => {
    if (uuid) {
      dispatch(fetchJobAsync(uuid));
    }
  }, [uuid]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAssumption());
    };
  }, []);

  const handleSelectCompany = (value: ICompany) => {
    console.log(value);
    setValues({
      ...values,
      company_uuid: value.company_uuid ?? "",
      company_name: value.company_name,
      company_logo: value.company_logo ?? "",
      company_location: value.company_location ?? "",
    });
  };

  const handleSelectCategory = (value: ICategory) => {
    console.log(value);
    setValues({
      ...values,
      category_uuid: value.category_uuid ?? "",
      category_name: value.category_name,
    });
  };

  return (
    <PageLoader
      loading={jobLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <PageContainer
        title={isUpdate ? "Edit Job" : "Create Job"}
        description="this is inner page"
      >
        <Breadcrumb title="Job" items={BCrumb} />
        <StandardCard
          sx={{ ml: 2, mr: 2 }}
          heading={`${isUpdate ? "Update" : "Create"} Job`}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* {values.company_uuid && (
                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Company Logo</CustomFormLabel>
                  <ImageField value={values.company_logo} />
                </Grid>
              )} */}

              <Grid item xs={12} lg={4}>
                <CompanyAutoSearch
                  label="Select Company"
                  value={{
                    company_name: values.company_name,
                    company_uuid: values.company_uuid,
                  }}
                  onSelect={(value) => handleSelectCompany(value)}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <CategoryAutoSearch
                  label="Select Category"
                  value={{
                    category_name: values.category_name,
                    category_uuid: values.category_uuid,
                  }}
                  onSelect={(value) => handleSelectCategory(value)}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Job Title"}
                  type="text"
                  id="job_title"
                  fullWidth
                  value={values.job_title}
                  onChange={handleChange}
                  error={errors.job_title ? true : false}
                  helperText={errors.job_title}
                />
              </Grid>

              {/* <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Company Location"}
                  type="text"
                  id="company_location"
                  fullWidth
                  value={values.company_location}
                  onChange={handleChange}
                  error={errors.company_location ? true : false}
                  helperText={errors.company_location}
                />
              </Grid> */}

              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Salary"}
                  type="text"
                  id="salary"
                  fullWidth
                  value={values.salary}
                  onChange={handleChange}
                  error={errors.salary ? true : false}
                  helperText={errors.salary}
                />
              </Grid>

              {/* <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Type"}
                  type="text"
                  id="type"
                  fullWidth
                  value={values.type}
                  onChange={handleChange}
                  error={errors.type ? true : false}
                  helperText={errors.type}
                />
              </Grid> */}

              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Job Location"}
                  type="text"
                  id="job_location"
                  fullWidth
                  value={values.job_location}
                  onChange={handleChange}
                  error={errors.job_location ? true : false}
                  helperText={errors.job_location}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Skills</CustomFormLabel>
                {/* <TextFieldWithLabel
                  heading={"Skills"}
                  type="text"
                  id="skills"
                  fullWidth
                  value={values.skills}
                  onChange={handleChange}
                  error={errors.skills ? true : false}
                  helperText={errors.skills}
                /> */}
                <FreeSoloMultiplesCreatable
                  options={[
                    { title: "JavaScript" },
                    { title: "TypeScript" },
                    { title: "Python" },
                    { title: "Java" },
                    { title: "C++" },
                    { title: "C#" },
                    { title: "PHP" },
                    { title: "Ruby" },
                    { title: "SQL" },
                    { title: "HTML" },
                    { title: "CSS" },
                    { title: "React" },
                    { title: "Angular" },
                    { title: "Vue.js" },
                    { title: "Node.js" },
                    { title: "Project Management" },
                    { title: "Agile" },
                    { title: "Scrum" },
                    { title: "Communication" },
                    { title: "Problem Solving" },
                    { title: "Team Leadership" },
                    { title: "Data Analysis" },
                    { title: "Machine Learning" },
                    { title: "Cloud Computing" },
                    { title: "DevOps" },
                    { title: "UI/UX Design" },
                    { title: "Power BI" },
                    { title: "SQL" },
                    { title: "Advance  Excel" },
                    { title: "Administrative Work" },
                    { title: "Technical Expertise" },
                  ]}
                  value={values.skills || ""}
                  onChange={(value) => setFieldValue("skills", value)}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Posted By"}
                  type="text"
                  id="posted_by"
                  fullWidth
                  value={values.posted_by}
                  onChange={handleChange}
                  error={errors.posted_by ? true : false}
                  helperText={errors.posted_by}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Category name"}
                  type="text"
                  id="category_name"
                  fullWidth
                  value={values.category_name}
                  onChange={handleChange}
                  error={errors.category_name ? true : false}
                  helperText={errors.category_name}
                />
              </Grid>

              {/* <Grid item xs={12} lg={4}>
                <TextFieldWithLabel
                  heading={"Qualification"}
                  type="text"
                  id="qualification"
                  fullWidth
                  value={values.qualification}
                  onChange={handleChange}
                  error={errors.qualification ? true : false}
                  helperText={errors.qualification}
                />
              </Grid> */}

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Type</CustomFormLabel>
                <CustomSelect
                  name="type"
                  id="type"
                  value={values.type || ""}
                  onChange={handleChange}
                  error={errors.type ? true : false}
                  fullWidth
                >
                  <MenuItem value="">Select Type</MenuItem>
                  <MenuItem value="Full Time">Full Time</MenuItem>
                  <MenuItem value="Part Time">Part Time</MenuItem>
                  <MenuItem value="Contract">Contract</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Industry</CustomFormLabel>
                <CustomSelect
                  name="industry"
                  id="industry"
                  value={values.industry || ""}
                  onChange={handleChange}
                  error={errors.industry ? true : false}
                  fullWidth
                >
                  <MenuItem value="">Select Industry</MenuItem>
                  <MenuItem value="Industry">Industry</MenuItem>
                  <MenuItem value="Technology">Technology</MenuItem>
                  <MenuItem value="Healthcare">Healthcare</MenuItem>
                  <MenuItem value="Finance">Finance</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Qualification</CustomFormLabel>
                <CustomSelect
                  name="qualification"
                  id="qualification"
                  value={values.qualification || ""}
                  onChange={handleChange}
                  error={errors.qualification ? true : false}
                  fullWidth
                >
                  <MenuItem value="">Select Qualification</MenuItem>
                  <MenuItem value="Bachelor">Bachelor</MenuItem>
                  <MenuItem value="Master">Master</MenuItem>
                  <MenuItem value="PhD">PhD</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={4}>
                <CustomFormLabel>Career Level</CustomFormLabel>
                <CustomSelect
                  name="career_level"
                  id="career_level"
                  value={values.career_level || ""}
                  onChange={handleChange}
                  error={errors.career_level ? true : false}
                  fullWidth
                >
                  <MenuItem value="">Select Career Level</MenuItem>
                  <MenuItem value="Entry">Entry</MenuItem>
                  <MenuItem value="Mid">Mid</MenuItem>
                  <MenuItem value="Senior">Senior</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={12}>
                <CustomFormLabel>Experience</CustomFormLabel>
                <CustomSelect
                  name="experience"
                  id="experience"
                  value={values.experience || ""}
                  onChange={handleChange}
                  error={errors.experience ? true : false}
                  fullWidth
                >
                  <MenuItem value="">Select Experience</MenuItem>
                  <MenuItem value="0-2 years">0-2 years</MenuItem>
                  <MenuItem value="3-5 years">3-5 years</MenuItem>
                  <MenuItem value="5+ years">5+ years</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextFieldWithLabel
                  heading={"Additional Details"}
                  type="text"
                  id="additional_details"
                  fullWidth
                  multiline
                  rows={2}
                  value={values.additional_details}
                  onChange={handleChange}
                  error={errors.additional_details ? true : false}
                  helperText={errors.additional_details}
                />
              </Grid>

              {/* <Grid item xs={12} lg={4}>
                <CustomFormLabel>Status</CustomFormLabel>
                <CustomSelect
                  value={values.status}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={10}>ACTIVE</MenuItem>
                  <MenuItem value={20}>INACTIVE</MenuItem>
                </CustomSelect>
              </Grid> */}

              <Grid item xs={12} lg={12}>
                <CustomFormLabel>Description</CustomFormLabel>
                <QuillEditor
                  value={values.description as string}
                  onChange={(html) => setFieldValue("description", html)}
                />
              </Grid>
            </Grid>
            <Stack direction={"row"} spacing={2} marginTop={4}>
              <Button type="submit" variant="contained" disabled={hasSpaces}>
                Save
              </Button>
            </Stack>
          </form>
        </StandardCard>
      </PageContainer>
    </PageLoader>
  );
};
function setCourseName(arg0: any) {
  throw new Error("Function not implemented.");
}
