import { IQueryParams, ISecondQueryParams } from "./common.types";

export const getSearchQuery = (queryParams: IQueryParams): string => {
  const {
    page,
    rowsPerPage,
    status,
    value,
    columns,
    fromDate,
    toDate,
    country_uuid,
  } = queryParams;
  let query = "?";
  if (status !== "-1") {
    query += `status=${status}&`;
  }
  if (columns.length > 0 && value.length > 0) {
    query += `columns=${columns}&`;
  }
  if (columns.length > 0 && value.length > 0) {
    query += `value=${value}&`;
  }
  if (fromDate) {
    query += `from_date=${fromDate}&`;
  }
  if (toDate) {
    query += `to_date=${toDate}&`;
  }

  if (country_uuid) {
    query += `country_uuid=${country_uuid}&`;
  }
  query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
  return query;
};

export const getleadSearchQuery = (queryParams: ISecondQueryParams): string => {
  const { page, rowsPerPage, fromDate, toDate } = queryParams;
  let query = "?";

  if (fromDate) {
    query += `from_date=${fromDate}&`;
  }
  if (toDate) {
    query += `to_date=${toDate}&`;
  }
  query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
  return query;
};
