import { Autocomplete, Box, CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";

import { CustomFormLabel, CustomTextField } from "../formsComponents";

import { useDispatchWrapper } from "../../hooks";
import { IJob } from "../../redux/HR/Jobs/job.types";
import { initialJob } from "../../redux/HR/Jobs";

const INITIAL_STATE: IJob = initialJob;
interface IJobAutoSearch {
  label?: string;
  value: { job_uuid: string | null; job_name: string } | null;
  onSelect: (value: IJob) => void;
  disabled?: boolean;
  error?: string;
  default?: string;
  inputWidth?: string;
}

export const JobAutoSearch: React.FC<IJobAutoSearch> = (props) => {
  const { label, value, onSelect, disabled, error, inputWidth } = props;

  // states
  const [options, setOptions] = React.useState<readonly IJob[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [search, setSearchText] = React.useState<any>("");
  const dispatch = useDispatchWrapper();

  // fetch options from api
  const fetchSuggestion = async (searchValue: string) => {
    setLoading(true);
    try {
      let apiEndpoint = "";
      if (searchValue) {
        apiEndpoint = `?value=${searchValue}&columns=job_name&status=ACTIVE`;
      }
      const res = await api.get(`/hr/get-jobs${apiEndpoint}`);
      const data: IJob[] = res.data.data;

      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  //get option label
  const getOptionLabel = (option: string | IJob) => {
    if (typeof option === "string") return option;
    return `${option.job_title || ""}`;
  };

  // get the saved value
  const getValue = () => {
    if (!value) return null;
    if (typeof value === "object" && value !== null) {
      return (
        options.find((option) => option.job_uuid === value.job_uuid) || null
      );
    }
    return null;
  };

  const getValueLabel = (value: IJob | null) => {
    if (value && typeof value === "object") {
      return value.job_title;
    }
    return "";
  };

  React.useEffect(() => {
    if (value && value.job_uuid && value.job_uuid.length > 0) {
      const option: IJob = {
        ...initialJob,
        job_uuid: value.job_uuid,
        job_title: value.job_name,
      };
      setOptions([option]);
    }
  }, [value]);

  React.useEffect(() => {
    if (
      search &&
      search.trim().length > 0 &&
      search.trim() !== value?.job_uuid
    ) {
      debounceFn(search);
    }
  }, [search]);

  console.log(value?.job_uuid, ".......");

  // ***********Right panel sart************
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogData, setDialogData] = React.useState<IJob>(initialJob);
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      ...initialJob,
      job_title: search,
    });
  };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   dispatch(clearCategory());
  // };
  // const handleComplete = (data: ICategory) => {
  //   setOpenDialog(false);
  //   dispatch(clearCategory());
  //   onSelect(data);
  // };
  // ************Right panel ends**********

  React.useEffect(() => {
    if (openData) {
      debounceFn(search);
    }
  }, [openData, search]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {search.length > 0 && options.length === 0 && !loading && (
            <Box
              sx={{
                paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenDialog()}
            ></Box>
          )}
        </Box>
      )}
      <Autocomplete
        id="job-auto-search"
        freeSolo
        options={options}
        value={options.find((job) => job.job_uuid === value?.job_uuid) || null}
        getOptionLabel={getOptionLabel}
        noOptionsText="No Job"
        filterOptions={(x) => x}
        onFocus={() => {
          debounceFn("");
        }}
        isOptionEqualToValue={(option, value) =>
          typeof option !== "string" && typeof value !== "string"
            ? option.job_uuid === value.job_uuid
            : option === value
        }
        loading={loading}
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: IJob | null,
        ) => {
          if (newValue && typeof newValue === "object") {
            const isOptionExists = options.some(
              (option) => option.job_uuid === newValue.job_uuid,
            );
            setOptions(isOptionExists ? options : [newValue, ...options]);
            onSelect(newValue);
          } else {
            onSelect(INITIAL_STATE);
          }
        }}
        autoComplete
        includeInputInList
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        renderInput={(params) => (
          <CustomTextField
            // defaultValue="Select Category"
            {...params}
            // sx={{ width: inputWidth || "250px" }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disabled={disabled}
            error={error ? true : false}
            helperText={error}
            placeholder={"Search By Job Title"}
          />
        )}
      />
    </>
  );
};
