import React from "react";
import { CustomSelect } from "../../../formsComponents";
import { IDateFilterDropdownProps } from "./interfaces/IDateFilterDropdownProps";
import { MenuItem } from "@mui/material";

export const DateFilterDropdown: React.FC<IDateFilterDropdownProps> = (
  props,
) => {
  const { value, options, sx, onChange, excludeOptions } = props;

  return (
    <CustomSelect
      sx={{ minWidth: "120px", ...sx }}
      value={value}
      onChange={onChange}
    >
      {options
        .filter(
          (x) => !(excludeOptions && excludeOptions.includes(x.value as any)),
        )
        .map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
    </CustomSelect>
  );
};
