import { LoadState } from "../../../constants/enums";
import { IJob, IJobState } from "./job.types";

export const initialJob: IJob = {
  job_uuid: "",
  company_name: "",
  company_uuid: "",
  company_logo: "",
  industry: "",
  job_title: "",
  company_location: "",
  salary: "",
  type: "",
  is_urgent: 1,
  verified: 1,
  job_location: "",
  description: "",
  skills: "",
  posted_by: "",
  category_name: "",
  category_uuid: "",
  qualification: "",
  experience: "",
  deadline_date: "",
  career_level: "",
  additional_details: null,
  status: "ACTIVE",
  created_by_uuid: "",
  modified_by_uuid: "",
  create_ts: "",
  insert_ts: "",
};

export const initialJobState: IJobState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  job: {
    data: initialJob,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
