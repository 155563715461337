/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Link, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";

import { CustomSelect } from "../../components/formsComponents";

import { DataTable } from "../../components/Table/DataTable";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { formatDateWithTime } from "../../helpers";
import { useDispatchWrapper } from "../../hooks";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { IStoreState } from "../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { CompanyAutoSearch } from "../../components/AutoCompleteSearches/CompanyAutoSearch";
import { CountryPediaAutoSearch } from "../../components/AutoCompleteSearches/CountryPediaAutoSearch";
import {
  clearCountrypedia,
  fetchCountrypediaListAsync,
  upsertCountrypediaAsync,
} from "../../redux/countrypedia/countryPediaActions";
import { ICountryPedia } from "../../redux/countrypedia/countrypedia.types";
import { IJob } from "../../redux/HR/Jobs/job.types";
import {
  clearJob,
  fetchJobListAsync,
  initialJob,
  upsertJobAsync,
} from "../../redux/HR/Jobs";

import { ICompany } from "../../redux/HR/Company/company.types";
import { JobAutoSearch } from "../../components/AutoCompleteSearches/JobAutoSearch";
import { CategoryAutoSearch } from "../../components/AutoCompleteSearches/CategoryAutoSearch";

export const JobList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "Job",
    },
  ];
  const {
    data: JobList,
    totalRecords,
    loading,
  } = useSelector((storeState: IStoreState) => storeState.job.list);

  const [status, setStatus] = React.useState<IJob["status"] | "ACTIVE">("-1");
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [searchBy, setSearchBy] = React.useState<string>("-1");
  const [searchColumns, setSearchColumns] = React.useState<string[]>([
    "company_name",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [jobName, setJobName] = React.useState<string>("");
  const [selectCompany, setSelectCompany] = useState<number | null>(null);
  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });
  const [jobAutoSearch, setJobAutoSearch] = React.useState<IJob>(initialJob);
  const [autoSearch, setAutoSearch] = React.useState<string>("");
  const { date } = useDateFilter({ defaultType: "last28Days" });
  const [companyAutoSearch, setCompanyAutoSearch] =
    React.useState<ICompany | null>(null);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const jobDropdown = useSelector(
    (storeState: IStoreState) => storeState.job.list.data,
  );

  // handle delete/ active an item from list
  const handleActiveInactiveItem = (data: IJob) => {
    dispatch(
      upsertJobAsync(data, null, null, (isSuccess) => {
        if (isSuccess) {
          fetchList();
        }
        setSaveLoading(false);
      }),
    );
  };

  React.useEffect(() => {
    if (searchBy === "reset") {
      setSearchBy("-1");
      setSearchValue("");
    }
  }, [searchBy]);

  const dataTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "search_by",
            onRender: () => {
              return (
                <CustomSelect
                  sx={{ minWidth: "150px" }}
                  value={searchBy}
                  onChange={(e: any) =>
                    setSearchBy(e.target.value as "company_uuid")
                  }
                >
                  <MenuItem value="-1">Search By</MenuItem>
                  <MenuItem value="company_uuid">Company Name</MenuItem>
                  <MenuItem value="job_uuid">Job Title</MenuItem>
                  <MenuItem value="category_uuid">Category</MenuItem>
                  <MenuItem sx={{ color: "red" }} value="reset">
                    RESET
                  </MenuItem>
                </CustomSelect>
              );
            },
          },

          {
            key: "company",
            onRender: () => (
              <Box
                sx={{
                  minWidth: "220px",
                }}
              >
                {searchBy === "company_uuid" && (
                  <CompanyAutoSearch
                    label=""
                    value={
                      companyAutoSearch
                        ? {
                            company_uuid: "",
                            company_name: "",
                          }
                        : null
                    }
                    onSelect={(selectedCompany) =>
                      setSearchValue(selectedCompany.company_uuid)
                    }
                  />
                )}
                {searchBy === "job_uuid" && (
                  <JobAutoSearch
                    label=""
                    value={{ job_name: "", job_uuid: "" }}
                    onSelect={(selectedJob) =>
                      setSearchValue(selectedJob.job_uuid)
                    }
                  />
                )}
                {searchBy === "category_uuid" && (
                  <CategoryAutoSearch
                    label=""
                    value={{ category_name: "", category_uuid: "" }}
                    onSelect={(selectedCategory) =>
                      setSearchValue(selectedCategory.category_uuid)
                    }
                  />
                )}
              </Box>
            ),
          },

          // {
          //   key: "job",
          //   onRender: () => (
          //     <Box
          //       sx={{
          //         minWidth: "200px",
          //       }}
          //     >
          //       <JobAutoSearch
          //         value={{
          //           job_name: jobAutoSearch.job_title,
          //           job_uuid: jobAutoSearch.job_uuid,
          //         }}
          //         onSelect={(value) => setJobAutoSearch(value)}
          //       />
          //     </Box>
          //   ),
          // },

          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    { label: "Company Name", value: "company_name" },
                    {
                      label: "Job Title",
                      value: "job_title",
                    },
                    {
                      label: "Job Location",
                      value: "job_location",
                    },
                    {
                      label: "Experience",
                      value: "experience",
                    },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IJob) => {
          return (
            <StandardTableActions
              status={row.status}
              onDeleteClick={() => {
                handleActiveInactiveItem({ ...row, status: "INACTIVE" });
              }}
              onReactiveClick={() => {
                handleActiveInactiveItem({ ...row, status: "ACTIVE" });
              }}
              onViewClick={() => {
                navigate(`/all-applications/${row.job_uuid}`);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
      {
        key: "job_title",
        headerName: "Job Title",
        fieldName: "job_title",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
      },
      {
        key: "company_logo",
        headerName: "Company Logo",
        fieldName: "company_logo",
        renderType: RenderType.IMAGE,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value: any, row: any) => {
          const imageUrl =
            row.company_logo && row.company_logo.startsWith("img/")
              ? `https://api.1eor.com/${row.company_logo}`
              : row.company_logo;
          return (
            <img
              src={imageUrl}
              alt="company logo"
              style={{ width: "40px", height: "auto" }}
            />
          );
        },
      },
      {
        key: "company_name",
        headerName: "Company Name",
        fieldName: "company_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "company_location",
        headerName: "Company Location",
        fieldName: "company_location",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
      },

      {
        key: "deadline_date",
        headerName: "Deadline Date",
        fieldName: "deadline_date",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
    ],
    items: JobList,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };

  const fetchList = () => {
    dispatch(
      fetchJobListAsync(
        {
          status: status,
          page: pagination.pageNumber,
          rowsPerPage: pagination.rowsPerPage,
          columns: searchColumns,
          value: search,
          // job_uuid: autoSearch,
        },
        searchBy,
        searchValue,
      ),
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    date,
    status,
    search,
    jobName,
    autoSearch,
    selectCompany,
    // jobAutoSearch,
    searchValue,
    // companyAutoSearch,
    searchBy,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(clearJob());
    };
  });

  return (
    <PageContainer title="View Job" description="this is inner page">
      <Breadcrumb title="View Job" items={BCrumb} />
      <StandardCard
        heading="Job"
        rightHeading={
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.JOB}
            variant="contained"
            onClick={() => navigate("/job/manage")}
          >
            Create Job
          </RoleBasedCustomButton>
        }
      >
        <DataTable {...dataTableProps} />
      </StandardCard>
    </PageContainer>
  );
};
