import { ActionType } from "typesafe-actions";

import * as CategoryAction from "./categoryActions";

type Actions = typeof CategoryAction;

export type CategoryActions = ActionType<Actions>;

export * from "./categoryActions";
export * from "./categoryReducer";
export * from "./defaultState";
