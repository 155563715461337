import produce from "immer";
import { CountryActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import { defaultLeads, defaultleadState } from "./defaultState";
import {
  CLEAR_LEADS,
  CLEAR_LEADS_STATE,
  FETCH_LEADS_LIST_FAILED,
  FETCH_LEADS_LIST_PROGRESS,
  FETCH_LEADS_LIST_SUCCESS,
} from "./leadsActions";

export const leadReducer = (
  state: IStoreState["lead"] = defaultleadState,
  action: CountryActions,
) => {
  switch (action.type) {
    case FETCH_LEADS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_LEADS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_LEADS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_LEADS: {
      const newState = produce(state, (draftState) => {
        draftState.lead.loading = LoadState.NotLoaded;
        draftState.lead.data = defaultLeads;
      });
      return newState;
    }
    case CLEAR_LEADS_STATE: {
      return defaultleadState;
    }
    default: {
      return state;
    }
  }
};
