import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { IQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { getSearchQuery } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ICategory } from "./category.types";

//=============================== Category List ===============================

export const FETCH_CATEGORY_LIST_PROGRESS = "FETCH_CATEGORY_LIST_PROGRESS";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILED = "FETCH_CATEGORY_LIST_FAILED";

export const fetchCategoryListProgress = () =>
  action(FETCH_CATEGORY_LIST_PROGRESS);
export const fetchCategoryListSuccess = (
  data: ICategory[],
  totalRecords: number,
) => action(FETCH_CATEGORY_LIST_SUCCESS, { data, totalRecords });
export const fetchCategoryListFailed = () => action(FETCH_CATEGORY_LIST_FAILED);

export const fetchCategoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCategoryListProgress());
      const res = await api.get(`/hr/get-category${searchQuery}`);
      const data: ICategory[] = res.data.data;

      console.log(data);

      const totalRecords = res.data.totalRecords;

      console.log(data, "records");

      dispatch(fetchCategoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Category ===============================

export const upsertCategoryAsync =
  (
    data: ICategory,

    onCallback: (isSuccess: boolean, Category?: ICategory) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    let { create_ts, insert_ts, ...rest } = data;

    try {
      dispatch(saveLoaderProgress());

      const res = await api.post("/hr/upsert-category", {
        ...rest,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Category saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message ||
            "Error occurred while saving Category",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
//*********************** fetching single data **********************//

export const FETCH_CATEGORY_PROGRESS = "FETCH_CATEGORY_PROGRESS";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";

export const fetchCategoryProgress = () => action(FETCH_CATEGORY_PROGRESS);
export const fetchCategorySuccess = (data: ICategory) =>
  action(FETCH_CATEGORY_SUCCESS, { data });
export const fetchCategoryFailed = (errorMessage: string) =>
  action(FETCH_CATEGORY_FAILED, { errorMessage });

export const fetchCategoryAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCategoryProgress());
      const res = await api.get(`/hr/get-category?category_uuid=${uuid}`);
      const data: ICategory[] = res.data.data;
      console.log(data, "data");
      if (data.length > 0) {
        dispatch(fetchCategorySuccess(data[0]));
      } else {
        dispatch(fetchCategoryFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchCategoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";
export const clearCategory = () => action(CLEAR_CATEGORY);
export const clearCategoryState = () => action(CLEAR_CATEGORY_STATE);
