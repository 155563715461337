import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialJob, initialJobState } from "./defaultState";
import {
  CLEAR_JOB,
  CLEAR_JOB_STATE,
  FETCH_JOB_FAILED,
  FETCH_JOB_LIST_FAILED,
  FETCH_JOB_LIST_PROGRESS,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_PROGRESS,
  FETCH_JOB_SUCCESS,
  JobActions,
} from ".";

export const jobReducer = (
  state: IStoreState["job"] = initialJobState,
  action: JobActions,
) => {
  switch (action.type) {
    case FETCH_JOB_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_JOB_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_JOB_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_JOB_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.job.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_JOB_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.job.loading = LoadState.Loaded;
        draftState.job.data = data;
      });
      return newState;
    }
    case FETCH_JOB_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.job.loading = LoadState.Failed;
        draftState.job.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_JOB: {
      const newState = produce(state, (draftState) => {
        draftState.job.loading = LoadState.NotLoaded;
        draftState.job.data = initialJob;
        draftState.job.error = null;
      });
      return newState;
    }

    case CLEAR_JOB_STATE: {
      return initialJobState;
    }

    default: {
      return state;
    }
  }
};
