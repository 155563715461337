import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAllApplicationState {
  list: IAllApplication[];
  totalRecords: number;
  loading: LoadState;
  application: IAllApplication;
  applicationLoading: LoadState;
  errorApplication: string | null;
  userOffBoard: IUserOffboarding;
  userOffBoardLoading: LoadState;
  userOnBoard: IUserOnboarding;
  userOnBoardLoading: LoadState;
  rquestFormLoading: LoadState;
  requestFormList: IHrRequestFormDialog[];
  error: string | null;
}

export interface IHrRequestFormDialog {
  branch_name: string | null;
  type: string | null;
  role_id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  details: string | null;
  status: string;
  server: string | null;
  powerbroker: string | null;
  portal: string | null;
  applied_rating_services: string | null;
  insurer_portal_ids: string | null;
  notify_ribo: string | null;
  order_business_card: string | null;
  prior_brokerage: string | null;
}
export interface IJobExperience {
  job_title: string;
  from: string | null;
  to: string | null;
  employer_name: string;
  job_description: string;
  required_skills: string;
  applicant_skills: string;
  skills_match_percentage: string;
  additional_details: string | null;
  shortlist_status: string | null;
}

export interface IReference {
  name: string;
  company: string;
  position: string;
  phone: string;
}

export interface IAllApplication {
  application_uuid: string | null;
  job_uuid: string | null;
  company_name: string | null;
  company_uuid: string | null;
  category_name: string | null;
  category_uuid: string | null;
  application_number: string | null;
  first_name: string;
  last_name: string;
  date_of_birth: string | null;
  email: string;
  mailing_address: string | null;
  unit_or_suite: string | null;
  city: string;
  state_or_province: string;
  postal_code: string;
  country: string;
  mobile: string;
  additional_details: string | null;
  shortlist_status: string | null;
  phone: string;
  interview_date: string | null;
  interview_details: string | null;
  selected_rejected_on: string | null;
  selected_rejected_details: string | null;
  linkedin_profile: string;
  languages_spoken: string | null;
  languages_written: string | null;
  job_experience: IJobExperience[];
  reference: IReference[];
  comments: string;
  follow_up_date: string | null;
  required_skills: string | null;
  applicant_skills: string | null;
  skills_match_percentage: string | null;
  job_application_position: string;
  documents_path: string | null;
  status:
    | "SHORTLISTED"
    | "INTERVIEW SCHEDULED"
    | "SELECTED"
    | "REJECTED"
    | "ACTIVE"
    | "PENDING";
  created_by_uuid: string | null;
  modified_by_uuid: string | null;
  create_ts: string;
  insert_ts: string;
}
export interface IUserOffboarding {
  incremental_number: number | null;
  select_user: number | null;
  date: string;
  assigned_to_id: number | null;
  office_information: {
    portal_access_to_inactive: {
      required: string | null;
      send_email: string | null;
    };
    last_day_at_work: {
      required: string | null;
      send_email: string | null;
    };
    thank_you_email: {
      required: string | null;
      send_email: string | null;
    };
    ribo_notified: {
      required: string | null;
      send_email: string | null;
    };
    credential_cancelled: {
      required: string | null;
      send_email: string | null;
    };
    user_on_server: {
      required: string | null;
      send_email: string | null;
    };
    user_power_broker: {
      required: string | null;
      send_email: string | null;
    };
    user_on_aaxel_portal: {
      required: string | null;
      send_email: string | null;
    };
    orientation_document_provided: {
      required: string | null;
      send_email: string | null;
    };
    applied_rating_services: {
      required: string | null;
      send_email: string | null;
    };
    e_signature: {
      required: string | null;
      send_email: string | null;
    };
  };
  comments: string | null;
  status: string;
}

export const defaultJobExperience: IJobExperience = {
  job_title: "",
  from: null,
  to: null,
  employer_name: "",
  job_description: "",
  required_skills: "",
  applicant_skills: "",
  skills_match_percentage: "",
  additional_details: null,
  shortlist_status: null,
};

export const defaultReference: IReference = {
  name: "",
  company: "",
  position: "",
  phone: "",
};

export const initialApplication: IAllApplication = {
  application_uuid: null,
  job_uuid: null,
  company_name: null,
  company_uuid: null,
  category_name: null,
  category_uuid: null,
  application_number: null,
  first_name: "",
  last_name: "",
  date_of_birth: null,
  shortlist_status: null,
  email: "",
  interview_date: null,
  interview_details: null,
  selected_rejected_on: null,
  selected_rejected_details: null,
  mailing_address: null,
  unit_or_suite: null,
  city: "",
  skills_match_percentage: null,
  state_or_province: "",
  additional_details: null,
  postal_code: "",
  country: "",
  mobile: "",
  applicant_skills: null,
  phone: "",
  linkedin_profile: "",
  languages_spoken: null,
  languages_written: null,
  job_experience: [defaultJobExperience],
  reference: [defaultReference],
  comments: "",
  follow_up_date: null,
  job_application_position: "",
  required_skills: null,
  documents_path: null,
  status: "SHORTLISTED",
  created_by_uuid: null,
  modified_by_uuid: null,
  create_ts: "",
  insert_ts: "",
};

export const initialUserOffboarding: IUserOffboarding = {
  incremental_number: null,
  select_user: null,
  date: "",
  assigned_to_id: null,
  office_information: {
    portal_access_to_inactive: {
      required: null,
      send_email: null,
    },
    last_day_at_work: {
      required: null,
      send_email: null,
    },
    thank_you_email: {
      required: null,
      send_email: null,
    },
    ribo_notified: {
      required: null,
      send_email: null,
    },
    credential_cancelled: {
      required: null,
      send_email: null,
    },
    user_on_server: {
      required: null,
      send_email: null,
    },
    user_power_broker: {
      required: null,
      send_email: null,
    },
    user_on_aaxel_portal: {
      required: null,
      send_email: null,
    },
    orientation_document_provided: {
      required: null,
      send_email: null,
    },
    applied_rating_services: {
      required: null,
      send_email: null,
    },
    e_signature: {
      required: null,
      send_email: null,
    },
  },
  comments: null,
  status: "ACTIVE",
};

export interface IUserOnboarding {
  application_number: string;
  office_information: {
    ribo_notified: {
      required: string | null;
      send_email: string | null;
    };
    insurer_credentials_ordered: {
      required: string | null;
      send_email: string | null;
    };
    user_on_server: {
      required: string | null;
      send_email: string | null;
    };
    user_on_power_broker: {
      required: string | null;
      send_email: string | null;
    };
    user_on_aaxel_portal: {
      required: string | null;
      send_email: string | null;
    };
    user_on_applied_rating_services: {
      required: string | null;
      send_email: string | null;
    };
    user_on_e_signature: {
      required: string | null;
      send_email: string | null;
    };
    orientation_organized: {
      required: string | null;
      send_email: string | null;
    };
    orientation_docmument_provided: {
      required: string | null;
      send_email: string | null;
    };
    training_organized: {
      required: string | null;
      send_email: string | null;
    };
    training_docmument_provided: {
      required: string | null;
      send_email: string | null;
    };
    aris_agreement_sent: {
      required: string | null;
      send_email: string | null;
    };
    aris_agreement_signed: {
      required: string | null;
      send_email: string | null;
    };
    payroll_setup: {
      required: string | null;
      send_email: string | null;
    };
  };
  user_defaults: {
    role_assignment: {
      required: string | null;
      send_email: string | null;
    };
    branch_assignment: {
      required: string | null;
      send_email: string | null;
    };
    phone_number_assignment: {
      required: string | null;
      send_email: string | null;
    };
    email_account_assignment: {
      required: string | null;
      send_email: string | null;
    };
    security_group_assignment: {
      required: string | null;
      send_email: string | null;
    };
  };
  status: string;
}

export const initialUserOnboard: IUserOnboarding = {
  application_number: "",
  office_information: {
    ribo_notified: {
      required: null,
      send_email: null,
    },
    insurer_credentials_ordered: {
      required: null,
      send_email: null,
    },
    user_on_server: {
      required: null,
      send_email: null,
    },
    user_on_power_broker: {
      required: null,
      send_email: null,
    },
    user_on_aaxel_portal: {
      required: null,
      send_email: null,
    },
    user_on_applied_rating_services: {
      required: null,
      send_email: null,
    },
    user_on_e_signature: {
      required: null,
      send_email: null,
    },
    orientation_organized: {
      required: null,
      send_email: null,
    },
    orientation_docmument_provided: {
      required: null,
      send_email: null,
    },
    training_organized: {
      required: null,
      send_email: null,
    },
    training_docmument_provided: {
      required: null,
      send_email: null,
    },
    aris_agreement_sent: {
      required: null,
      send_email: null,
    },
    aris_agreement_signed: {
      required: null,
      send_email: null,
    },
    payroll_setup: {
      required: null,
      send_email: null,
    },
  },
  user_defaults: {
    role_assignment: {
      required: null,
      send_email: null,
    },
    branch_assignment: {
      required: null,
      send_email: null,
    },
    phone_number_assignment: {
      required: null,
      send_email: null,
    },
    email_account_assignment: {
      required: null,
      send_email: null,
    },
    security_group_assignment: {
      required: null,
      send_email: null,
    },
  },
  status: "ACTIVE",
};

export const initialAllApplicationState: IAllApplicationState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  application: initialApplication,
  applicationLoading: LoadState.NotLoaded,
  errorApplication: null,
  userOffBoard: initialUserOffboarding,
  userOffBoardLoading: LoadState.NotLoaded,
  userOnBoard: initialUserOnboard,
  userOnBoardLoading: LoadState.NotLoaded,
  rquestFormLoading: LoadState.NotLoaded,
  requestFormList: [],
  error: null,
};
