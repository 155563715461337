/* eslint-disable react-hooks/exhaustive-deps */

import { Button, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { useSelector } from "react-redux";

import { StandardCard } from "../../components/Cards";
import { LeadOppStatus } from "../../components/LeadOppStatus/LeadOppStatus";
import { DataTable } from "../../components/Table/DataTable";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { PageContainer } from "../../components/container/PageContainer";
import { CustomSelect } from "../../components/formsComponents";
import { LoadState } from "../../constants/enums";
import { useDispatchWrapper } from "../../hooks";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { ICountry } from "../../redux/country/country.types";
import { IStoreState } from "../../redux/initialStoreState";
import { ManageCategory } from "./ManageCategory";

import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import {
  clearCategoryState,
  defaultCategory,
  fetchCategoryListAsync,
  upsertCategoryAsync,
} from "../../redux/HR/Category";
import { ICategory } from "../../redux/HR/Category/category.types";

export const CategoryList: React.FC = () => {
  const navigate = useNavigate();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/hr",
      title: "HR",
    },
    {
      title: "category",
    },
  ];
  const INITIAL_CATEGORY = defaultCategory;
  const {
    data: categoryList,
    totalRecords,
    loading,
  } = useSelector((storeState: IStoreState) => storeState.category.list);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const [status, setStatus] = React.useState<ICategory["status"] | "-1">(
    "ACTIVE",
  );
  const [searchColumns, setSearchColumns] = React.useState<string[]>([
    "category_name",
  ]);
  const [search, setSearch] = React.useState<string>(" ");
  const [pagination, setPagination] = React.useState<{
    pageNumber: number;
    rowsPerPage: number;
  }>({ pageNumber: 1, rowsPerPage: 10 });

  // handle delete/ active an item from list
  const handleActiveInactiveItem = (data: ICategory) => {
    dispatch(
      upsertCategoryAsync(data, (isSuccess) => {
        if (isSuccess) {
          fetchList();
        }
        setSaveLoading(false);
      }),
    );
  };

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });

  const [openConfirmDialog, setOpenConfirmDialog] =
    React.useState<ICategory | null>(null);
  const dispatch = useDispatchWrapper();

  const [viewDetails, setViewDetails] = React.useState<ICategory | null>(null);

  //delete country logic
  const handleOpenConfirmDialog = (category: ICategory) => {
    dispatch(
      upsertCategoryAsync({ ...category, status: "INACTIVE" }, (isSuccess) => {
        if (isSuccess) {
          fetchList();
        }
      }),
    );
    setOpenConfirmDialog(null);
  };

  const productsTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) =>
                  setStatus(e.target.value as ICategory["status"])
                }
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </CustomSelect>
            ),
          },

          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    { label: "Category Name", value: "category_name" },
                    { label: "Type", value: "type" },
                    { label: "Description", value: "description" },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICategory) => {
          return (
            // <Box
            //   onClick={() => setViewDetails(row)}
            //   sx={{
            //     cursor: "pointer",
            //   }}
            // >
            //   <Tooltip title="Edit">
            //     <EditIcon color="primary" />
            //   </Tooltip>
            // </Box>

            <StandardTableActions
              status={row.status}
              onDeleteClick={() => {
                handleActiveInactiveItem({ ...row, status: "INACTIVE" });
              }}
              onReactiveClick={() => {
                handleActiveInactiveItem({ ...row, status: "ACTIVE" });
              }}
              onEditClick={() => setViewDetails(row)}
              // onAssumptionClick={() =>
              //   navigate(
              //     `/assumption?country_name=${row.country_name}/${row.country_uuid}`,
              //     {
              //       state: {
              //         country_uuid: row.country_uuid,
              //         country_name: row.country_name,
              //       },
              //     },
              //   )
              // }
              // onSalaryClick={() =>
              //   navigate(
              //     `/salarycomponent?country_name=${row.country_name}/${row.country_uuid}`,
              //     {
              //       state: {
              //         country_uuid: row.country_uuid,
              //         country_name: row.country_name,
              //       },
              //     },
              //   )
              // }
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICountry) => {
          return <LeadOppStatus status={row.status} />;
        },
      },
      {
        key: "category_name",
        headerName: "Category Name",
        fieldName: "category_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },

      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "description",
        headerName: "Description",
        fieldName: "description",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      // {
      //   key: "isunique_currencies",
      //   headerName: "Isunique Currency",
      //   fieldName: "isunique_currencies",
      //   renderType: RenderType.TEXT,
      //   exportCellWidth: 30,
      //   enableSorting: true,
      // },
    ],
    items: categoryList,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };

  const fetchList = () => {
    dispatch(
      fetchCategoryListAsync({
        status: status,
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
        columns: searchColumns,
        value: search,
      }),
    );
  };

  const handleEditComplete = () => {
    fetchList();
    setViewDetails(null);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, date, status, search, date]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCategoryState());
    };
  }, []);

  return (
    <PageContainer title="View Category" description="this is innerpage">
      <Breadcrumb title="View Category" items={BCrumb} />

      <StandardCard
        heading="Category List"
        rightHeading={
          <Button
            variant="contained"
            onClick={() => setViewDetails(INITIAL_CATEGORY)}
          >
            Create Category
          </Button>
        }
      >
        <DataTable {...productsTableProps} />
      </StandardCard>

      {viewDetails && (
        <ManageCategory
          open={true}
          category={viewDetails}
          onClose={() => setViewDetails(null)}
          onComplete={handleEditComplete}
        />
      )}

      {openConfirmDialog && (
        <ConfirmDialog
          open={!!openConfirmDialog}
          content="You want to delete the category?"
          onConfirm={() => handleOpenConfirmDialog(openConfirmDialog)}
          onClose={() => setOpenConfirmDialog(null)}
        />
      )}
    </PageContainer>
  );
};
