import { LoadState } from "../../../constants/enums";
import { ICompany, ICompanyState } from "./company.types";

export const initialCompany: ICompany = {
  company_uuid: "",
  company_name: "",
  company_location: "",
  type: "",
  description: "",
  jobs_category: "",
  additional_details: null,
  status: "ACTIVE",
  created_by_uuid: "",
  modified_by_uuid: "",
  create_ts: "",
  insert_ts: "",
  company_logo: null,
};

export const initialCompanyState: ICompanyState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  company: {
    data: initialCompany,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
