import { Autocomplete, Box, CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";

import { CustomFormLabel, CustomTextField } from "../formsComponents";

import { useDispatchWrapper } from "../../hooks";
import { ICompany } from "../../redux/HR/Company/company.types";
import { initialCompany } from "../../redux/HR/Company";

const INITIAL_STATE: ICompany = initialCompany;
interface ICompanyAutoSearch {
  label: string;
  value: { company_uuid: string | null; company_name: string } | null;
  onSelect: (value: ICompany) => void;
  disabled?: boolean;
  error?: string;
  default?: string;
  inputWidth?: string;
}

export const CompanyAutoSearch: React.FC<ICompanyAutoSearch> = (props) => {
  const { label, value, onSelect, disabled, error, inputWidth } = props;

  // states
  const [options, setOptions] = React.useState<readonly ICompany[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [search, setSearchText] = React.useState<any>("");
  const dispatch = useDispatchWrapper();

  // fetch options from api
  const fetchSuggestion = async (searchValue: string) => {
    setLoading(true);
    try {
      let apiEndpoint = "";
      if (searchValue) {
        apiEndpoint = `&value=${searchValue}&columns=company_name`;
      }
      const res = await api.get(`/hr/get-company?status=ACTIVE${apiEndpoint}`);
      const data: ICompany[] = res.data.data;

      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  //get option label
  const getOptionLabel = (option: string | ICompany) => {
    if (typeof option === "string") return option;
    return `${option.company_name || ""}`;
  };

  // get the saved value
  const getValue = () => {
    if (value && typeof value === "object") {
      return (
        options.find((option) => option.company_name === value?.company_uuid) ||
        null
      );
    }
    return options.find((option) => option.company_uuid === value) || null;
  };

  const getValueLabel = (value: ICompany | null) => {
    if (value && typeof value === "object") {
      return value.company_name;
    }
    return "";
  };

  React.useEffect(() => {
    if (value && value.company_uuid && value.company_uuid.length > 0) {
      const option: ICompany = {
        ...initialCompany,
        company_uuid: value.company_uuid,
        company_name: value.company_name,
      };
      setOptions([option]);
    }
  }, [value]);

  // ***********Right panel sart************
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogData, setDialogData] = React.useState<ICompany>(initialCompany);
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      ...initialCompany,
      company_name: search,
    });
  };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   dispatch(clearCompany());
  // };
  // const handleComplete = (data: ICompany) => {
  //   setOpenDialog(false);
  //   dispatch(clearCompany());
  //   onSelect(data);
  // };
  // ************Right panel ends**********

  React.useEffect(() => {
    if (openData) {
      debounceFn(search);
    }
  }, [openData, search]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {search.length > 0 && options.length === 0 && !loading && (
            <Box
              sx={{
                paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenDialog()}
            ></Box>
          )}
        </Box>
      )}
      <Autocomplete
        id="company-auto-search"
        freeSolo
        options={options}
        value={getValueLabel(getValue())}
        getOptionLabel={getOptionLabel}
        noOptionsText="No Company"
        filterOptions={(x) => x}
        onFocus={() => setOpenData(true)}
        loading={loading}
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: ICompany | null,
        ) => {
          if (newValue && typeof newValue === "object") {
            const isOptionExists = options.some(
              (option) => option.company_uuid === newValue.company_uuid,
            );
            setOptions(isOptionExists ? options : [newValue, ...options]);
            onSelect(newValue);
          } else {
            onSelect(INITIAL_STATE);
          }
        }}
        autoComplete
        includeInputInList
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        renderOption={(props, option: string | ICompany, state) => {
          const companyOption = typeof option === "string" ? null : option;
          return (
            <Box
              component="li"
              {...props}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {companyOption?.company_logo && (
                <img
                  src={`https://api.1eor.com/${companyOption.company_logo}`}
                  alt={companyOption.company_logo}
                  style={{ width: 30, height: 30, marginRight: 10 }}
                />
              )}
              {typeof option === "string" ? option : option.company_name}
            </Box>
          );
        }}
        renderInput={(params) => {
          const selectedCompany = getValue();
          return (
            <CustomTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {selectedCompany?.company_logo && (
                      <img
                        src={`https://api.1eor.com/${selectedCompany.company_logo}`}
                        alt={selectedCompany.company_name}
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />
                    )}
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
              error={error ? true : false}
              helperText={error}
              placeholder={
                value ? (value?.company_name as string) : "Search Company"
              }
            />
          );
        }}
      />
    </>
  );
};
