import { Autocomplete, Box, CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";

import { CustomFormLabel, CustomTextField } from "../formsComponents";

import { useDispatchWrapper } from "../../hooks";
import { ICategory } from "../../redux/HR/Category/category.types";
import { defaultCategory } from "../../redux/HR/Category";

const INITIAL_STATE: ICategory = defaultCategory;
interface ICategoryAutoSearch {
  label: string;
  value: { category_uuid: string | null; category_name: string } | null;
  onSelect: (value: ICategory) => void;
  disabled?: boolean;
  error?: string;
  default?: string;
  inputWidth?: string;
}

export const CategoryAutoSearch: React.FC<ICategoryAutoSearch> = (props) => {
  const { label, value, onSelect, disabled, error, inputWidth } = props;

  // states
  const [options, setOptions] = React.useState<readonly ICategory[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [search, setSearchText] = React.useState<any>("");
  const dispatch = useDispatchWrapper();

  // fetch options from api
  const fetchSuggestion = async (searchValue: string) => {
    setLoading(true);
    try {
      let apiEndpoint = "";
      if (searchValue) {
        apiEndpoint = `?value=${searchValue}&columns=category_name&status=ACTIVE`;
      }
      const res = await api.get(`/hr/get-category${apiEndpoint}`);
      const data: ICategory[] = res.data.data;

      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  //get option label
  const getOptionLabel = (option: string | ICategory) => {
    if (typeof option === "string") return option;
    return `${option.category_name || ""}`;
  };

  // get the saved value
  const getValue = () => {
    if (value && typeof value === "object") {
      return (
        options.find(
          (option) => option.category_name === value?.category_uuid,
        ) || null
      );
    }
    return options.find((option) => option.category_uuid === value) || null;
  };

  const getValueLabel = (value: ICategory | null) => {
    if (value && typeof value === "object") {
      return value.category_name;
    }
    return "";
  };

  React.useEffect(() => {
    if (value && value.category_uuid && value.category_uuid.length > 0) {
      const option: ICategory = {
        ...defaultCategory,
        category_uuid: value.category_uuid,
        category_name: value.category_name,
      };
      setOptions([option]);
    }
  }, [value]);

  // ***********Right panel sart************
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogData, setDialogData] =
    React.useState<ICategory>(defaultCategory);
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogData({
      ...defaultCategory,
      category_name: search,
    });
  };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   dispatch(clearCategory());
  // };
  // const handleComplete = (data: ICategory) => {
  //   setOpenDialog(false);
  //   dispatch(clearCategory());
  //   onSelect(data);
  // };
  // ************Right panel ends**********

  React.useEffect(() => {
    if (openData) {
      debounceFn(search);
    }
  }, [openData, search]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {search.length > 0 && options.length === 0 && !loading && (
            <Box
              sx={{
                paddingLeft: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 1,
                cursor: "pointer",
              }}
              onClick={() => handleOpenDialog()}
            ></Box>
          )}
        </Box>
      )}
      <Autocomplete
        id="category-auto-search"
        freeSolo
        options={options}
        value={getValueLabel(getValue())}
        getOptionLabel={getOptionLabel}
        noOptionsText="No Category"
        filterOptions={(x) => x}
        onFocus={() => setOpenData(true)}
        loading={loading}
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: ICategory | null,
        ) => {
          if (newValue && typeof newValue === "object") {
            const isOptionExists = options.some(
              (option) => option.category_uuid === newValue.category_uuid,
            );
            setOptions(isOptionExists ? options : [newValue, ...options]);
            onSelect(newValue);
          } else {
            onSelect(INITIAL_STATE);
          }
        }}
        autoComplete
        includeInputInList
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        renderInput={(params) => (
          <CustomTextField
            // defaultValue="Select Category"

            {...params}
            // sx={{ width: inputWidth || "250px" }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            disabled={disabled}
            error={error ? true : false}
            helperText={error}
            placeholder={
              value ? (value?.category_name as string) : "Search Category"
            }
          />
        )}
      />
    </>
  );
};
