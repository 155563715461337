import produce from "immer";
import { initialCompany, initialCompanyState } from "./defaultState";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_COMPANY,
  CLEAR_COMPANY_STATE,
  CompanyActions,
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_LIST_FAILED,
  FETCH_COMPANY_LIST_PROGRESS,
  FETCH_COMPANY_LIST_SUCCESS,
  FETCH_COMPANY_PROGRESS,
  FETCH_COMPANY_SUCCESS,
} from ".";
import { LoadState } from "../../../constants/enums";

export const companyReducer = (
  state: IStoreState["company"] = initialCompanyState,
  action: CompanyActions,
) => {
  switch (action.type) {
    case FETCH_COMPANY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_COMPANY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COMPANY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_COMPANY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.company.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COMPANY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.company.loading = LoadState.Loaded;
        draftState.company.data = data;
      });
      return newState;
    }
    case FETCH_COMPANY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.company.loading = LoadState.Failed;
        draftState.company.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_COMPANY: {
      const newState = produce(state, (draftState) => {
        draftState.company.loading = LoadState.NotLoaded;
        draftState.company.data = initialCompany;
        draftState.company.error = null;
      });
      return newState;
    }

    case CLEAR_COMPANY_STATE: {
      return initialCompanyState;
    }

    default: {
      return state;
    }
  }
};
