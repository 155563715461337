import React from "react";
import { CustomChip } from "../CustomChip";

export const LeadOppStatus: React.FC<{ status: string }> = (props) => {
  const { status } = props;

  switch (status) {
    case "HOT": {
      return <CustomChip color="error" content={"Hot"} size="small" />;
    }
    case "COLD": {
      return <CustomChip color="info" content={"Cold"} size="small" />;
    }

    case "WARM": {
      return <CustomChip color="warning" content={"Warm"} size="small" />;
    }

    default: {
      return <CustomChip color="info" content={status} size="small" />;
    }
  }
};
