import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { ILeads } from "./leads.types";
import { IQueryParams, ISecondQueryParams } from "../common/common.types";
import { getleadSearchQuery, getSearchQuery } from "../common/helpers";

//=============================== Country List ===============================

export const FETCH_LEADS_LIST_PROGRESS = "FETCH_LEADS_LIST_PROGRESS";
export const FETCH_LEADS_LIST_SUCCESS = "FETCH_LEADS_LIST_SUCCESS";
export const FETCH_LEADS_LIST_FAILED = "FETCH_LEADS_LIST_FAILED";

export const fetchLeadsListProgress = () => action(FETCH_LEADS_LIST_PROGRESS);
export const fetchLeadsListSuccess = (data: ILeads[], totalRecords: number) =>
  action(FETCH_LEADS_LIST_SUCCESS, { data, totalRecords });
export const fetchLeadsListFailed = () => action(FETCH_LEADS_LIST_FAILED);

export const fetchLeadsListAsync =
  (
    queryParams: ISecondQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getleadSearchQuery(queryParams);
      dispatch(fetchLeadsListProgress());
      const res = await api.get(`website/get-calculator-user${searchQuery}`);
      const data: ILeads[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchLeadsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchLeadsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchAllLeadsListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLeadsListProgress());
      const res = await api.get(`website/get-calculator-user`);
      const data: ILeads[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchLeadsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchLeadsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_LEADS = "CLEAR_LEADS";
export const CLEAR_LEADS_STATE = "CLEAR_LEADS_STATE";
export const clearCountry = () => action(CLEAR_LEADS);
export const clearCountryState = () => action(CLEAR_LEADS_STATE);
