import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";

import {
  CLEAR_COMMENT_LIST,
  CommentActions,
  FETCH_COMMENT_LIST_FAILED,
  FETCH_COMMENT_LIST_PROGRESS,
  FETCH_COMMENT_LIST_SUCCESS,
  defaultCommentState,
} from "../comments";

export const commentReducer = (
  state: IStoreState["comment"] = defaultCommentState,
  action: CommentActions,
) => {
  switch (action.type) {
    // ----------------------------------  Comment List----------------------------------
    case FETCH_COMMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.allComments.loading = LoadState.InProgress;
        draftState.allComments.data = [];
      });
      return newState;
    }
    case FETCH_COMMENT_LIST_SUCCESS: {
      const { data, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.allComments.loading = LoadState.Loaded;
        draftState.allComments.data = data;
        draftState.allComments.totalRecords = count;
      });
      return newState;
    }
    case FETCH_COMMENT_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.allComments.loading = LoadState.Failed;
        draftState.allComments.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_COMMENT_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.allComments.loading = LoadState.NotLoaded;
        draftState.allComments.data = [];
        draftState.allComments.error = null;
      });
      return newState;
    }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
