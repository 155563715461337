import { LoadState } from "../../constants/enums";
import { IComment, ICommentState } from "./comments.types";

export const defaultComment: IComment = {
  comment_t_uuid: null,
  parent_module_no: "",
  module_uuid: "",
  module_name: "Enquiry",
  comment_remark: "",
  created_by_name: null,
  created_by_uuid: null,
  status: "ACTIVE",
};

export const defaultCommentState: ICommentState = {
  allComments: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  singleComment: {
    loading: LoadState.NotLoaded,
    data: defaultComment,
    error: null,
  },
};
