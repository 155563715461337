import { ActionType } from "typesafe-actions";

import * as CompanyAction from "./companyActions";

type Actions = typeof CompanyAction;

export type CompanyActions = ActionType<Actions>;

export * from "./companyActions";
export * from "./companyReducer";
export * from "./defaultState";
