import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { IJob } from "./job.types";
import { IQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { getSearchQuery } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { uploadFile } from "../../../helpers/uploadFile";

//=============================== Job List ===============================

export const FETCH_JOB_LIST_PROGRESS = "FETCH_JOB_LIST_PROGRESS";
export const FETCH_JOB_LIST_SUCCESS = "FETCH_JOB_LIST_SUCCESS";
export const FETCH_JOB_LIST_FAILED = "FETCH_JOB_LIST_FAILED";

export const fetchJobListProgress = () => action(FETCH_JOB_LIST_PROGRESS);
export const fetchJobListSuccess = (data: IJob[], totalRecords: number) =>
  action(FETCH_JOB_LIST_SUCCESS, { data, totalRecords });
export const fetchJobListFailed = () => action(FETCH_JOB_LIST_FAILED);

export const fetchJobListAsync =
  (
    queryParams: IQueryParams,
    searchBy: string,
    searchValue: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let url = "";
      // if(jobUuid){
      //   url += `&job_uuid=${jobUuid}`
      // }
      //  if(companyUuid){
      //   url += `&company_uuid=${companyUuid}`
      // }

      // if(categoryUuid){
      //   url += `&category_uuid=${categoryUuid}`
      // }

      if (searchBy !== "-1" && searchBy !== "reset" && searchValue) {
        url += `&${searchBy}=${searchValue}`;
      }
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchJobListProgress());
      const res = await api.get(`/hr/get-jobs${searchQuery}${url}`);
      const data: IJob[] = res.data.data;

      console.log(data);

      const totalRecords = res.data.totalRecords;

      console.log(data, "records");

      dispatch(fetchJobListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchJobListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Job===============================

export const upsertJobAsync =
  (
    data: IJob,
    companyLogo: File | null,
    p0: null,
    onCallback: (isSuccess: boolean, Job?: IJob) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    let { create_ts, insert_ts, deadline_date, ...rest } = data;

    try {
      dispatch(saveLoaderProgress());

      const res = await api.post("/hr/upsert-jobs", {
        ...rest,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Job saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message || "Error occurred while saving Job",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  }; //*********************** fetching single data **********************//

export const FETCH_JOB_PROGRESS = "FETCH_JOB_PROGRESS";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
export const FETCH_JOB_FAILED = "FETCH_JOB_FAILED";

export const fetchJobProgress = () => action(FETCH_JOB_PROGRESS);
export const fetchJobSuccess = (data: IJob) =>
  action(FETCH_JOB_SUCCESS, { data });
export const fetchJobFailed = (errorMessage: string) =>
  action(FETCH_JOB_FAILED, { errorMessage });

export const fetchJobAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchJobProgress());
      const res = await api.get(`/hr/get-jobs?job_uuid=${uuid}`);
      const data: IJob[] = res.data.data;
      console.log(data, "data");
      if (data.length > 0) {
        dispatch(fetchJobSuccess(data[0]));
      } else {
        dispatch(fetchJobFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchJobFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_JOB = "CLEAR_JOB";
export const CLEAR_JOB_STATE = "CLEAR_JOB_STATE";
export const clearJob = () => action(CLEAR_JOB);
export const clearJobState = () => action(CLEAR_JOB_STATE);
