import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BuildTheme } from "../../../assets/global/Theme-variable";
import { IStoreState } from "../../../redux/initialStoreState";

export const ThemeSettings = () => {
  const customizer = useSelector(
    (state: IStoreState) => state.customizerReducer,
  );
  const theme = BuildTheme({
    direction: customizer.activeDir,
    theme: customizer.activeTheme,
  });
  useEffect(() => {
    document.dir = customizer.activeDir;
  }, [customizer]);

  return theme;
};
