import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { PageContainer } from "../../components/container/PageContainer";

export const OOPSError = () => (
  <PageContainer title="Error" description="this is Error page">
    <Box
      display="flex"
      flexDirection="column"
      height="40vh"
      textAlign="center"
      justifyContent="center"
      sx={{ backgroundColor: "#e4f5ff" }}
    >
      <Container maxWidth="md">
        <Typography
          align="center"
          variant="h1"
          sx={{
            pt: 2,
            color: (theme) =>
              `${
                theme.palette.mode === "dark"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.87)"
              }`,
          }}
        >
          OOPS! Error Occured
        </Typography>
        <Typography
          align="center"
          variant="h4"
          sx={{
            pt: 1,
            pb: 3,
            color: (theme) =>
              `${
                theme.palette.mode === "dark"
                  ? "rgba(0, 0, 0, 0.87)"
                  : "rgba(0, 0, 0, 0.87)"
              }`,
          }}
        >
          Please contact with adminstrator for this.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/"
          disableElevation
        >
          Go to home
        </Button>
      </Container>
    </Box>
  </PageContainer>
);
