import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import { IRadioButtonProps } from "./type";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 21,
  height: 21,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "rgba(0,0,0,0.4)" : "",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary
        : theme.palette.primary,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  boxShadow: "none",
  "&:before": {
    display: "block",
    width: 21,
    height: 21,
    backgroundImage:
      theme.palette.mode === "dark"
        ? `radial-gradient(#282C34,#282C34 28%,transparent 32%)`
        : "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
}));

// Inspired by blueprintjs
export const CustomRadio: React.FC<IRadioButtonProps> = ({
  bgcolor,
  ...props
}) => {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={
        <BpCheckedIcon
          sx={{
            backgroundColor: bgcolor || "primary.main",
          }}
        />
      }
      icon={<BpIcon />}
      {...props}
    />
  );
};
