import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
import { ICustomizerState } from "./customizer/customizerReducer";

// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { ICommonState, defaultCommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import {
  ISecurityState,
  initialSecurityState,
} from "./security/security.types";

import { ICommentState, defaultCommentState } from "./comments";

import { ICountryState } from "./country/country.types";
import { defaultCountryState } from "./country/defaultState";
import { IAssumptionState } from "./assumption/assumption.types";
import { initialAssumptionState } from "./assumption";
import { ISalaryState } from "./salary/salary.types";
import { initialSalaryState } from "./salary";
import { ICountryPediaState } from "./countrypedia/countrypedia.types";
import { initialCountryPediaState } from "./countrypedia";
import { ICountryKeyState } from "./countryKey/countrykey.types";
import { initialCountryKeyState } from "./countryKey";
import { ILeadState } from "./leads/leads.types";
import { defaultleadState } from "./leads/defaultState";
import {
  IAllApplicationState,
  initialAllApplicationState,
} from "./allApplications/allApplications.types";
import { ICompanyState } from "./HR/Company/company.types";
import { initialCompanyState } from "./HR/Company";
import { ICategoryState } from "./HR/Category/category.types";
import { defaultCategoryState } from "./HR/Category";
import { IJobState } from "./HR/Jobs/job.types";
import { initialJobState } from "./HR/Jobs";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;

  message: IMessagesState;

  userprofile: IUsersState;
  common: ICommonState;

  security: ISecurityState;
  category: ICategoryState;
  comment: ICommentState;
  lead: ILeadState;
  country: ICountryState;
  job: IJobState;
  countrypedia: ICountryPediaState;
  company: ICompanyState;
  assumption: IAssumptionState;
  salary: ISalaryState;
  countrykey: ICountryKeyState;
  allApplications: IAllApplicationState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "MAIN_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
  },

  company: initialCompanyState,

  message: initialMessagesState,
  lead: defaultleadState,
  common: defaultCommonState,

  userprofile: initialUserProfileState,

  security: initialSecurityState,

  comment: defaultCommentState,
  category: defaultCategoryState,
  country: defaultCountryState,
  job: initialJobState,
  countrypedia: initialCountryPediaState,
  assumption: initialAssumptionState,
  salary: initialSalaryState,
  countrykey: initialCountryKeyState,
  allApplications: initialAllApplicationState,
};
