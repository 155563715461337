import { useLocation } from "react-router-dom";

export const useQueryParms = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getParm = (name: string) => {
    const paramValue = queryParams.get(name);
    return paramValue;
  };

  return { getParm };
};
