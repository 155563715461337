import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  createFilterOptions,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import { CustomTextField } from "../formsComponents";

export interface OptionType {
  title: string;
  inputValue?: string; // Optional for new options
}

interface FreeSoloCreatableProps {
  options: OptionType[];
  label?: string;
  value: string; // Value as a comma-separated string
  onChange: (value: string) => void;
  width?: number;
}

const filter = createFilterOptions<OptionType>();

const FreeSoloMultiplesCreatable: React.FC<FreeSoloCreatableProps> = ({
  options,
  label,
  value,
  onChange,
}) => {
  // Convert comma-separated string to OptionType[]
  const currentValue: OptionType[] = value
    ? value.split(", ").map((title) => ({ title }))
    : [];

  return (
    <Autocomplete
      multiple
      value={currentValue}
      onChange={(event, newValue) => {
        const updatedValue: OptionType[] = newValue.map((item) => {
          if (typeof item === "string") {
            return { title: item };
          } else if (item && (item as OptionType).inputValue) {
            return { title: (item as OptionType).inputValue || "" };
          } else {
            return item as OptionType;
          }
        });

        const commaSeparatedValues = updatedValue
          .map((option) => option.title || "")
          .join(", ");
        onChange(commaSeparatedValues);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.title,
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-create-option"
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.title; // title is guaranteed to be a string
      }}
      renderOption={(props, option, state: AutocompleteRenderOptionState) => (
        <li
          {...props}
          key={
            (option as OptionType).inputValue || (option as OptionType).title
          }
        >
          {(option as OptionType).title}
        </li>
      )}
      freeSolo
      fullWidth
      sx={{
        ".MuiOutlinedInput-root": {
          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: "0.8rem",
          color: "rgb(38, 38, 38)",
          width: "100%",
        },
      }}
      renderInput={(params) => <CustomTextField {...params} label={label} />}
    />
  );
};

export default FreeSoloMultiplesCreatable;
