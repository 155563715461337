import { Autocomplete, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { CustomTextField } from "../formsComponents";
import { ISelectOption } from "../../constants/types";
import { debounce } from "lodash";
import { api } from "../../api/api";
import { IAutoCompleteSearchesProps } from "./AutoCompleteSearches.types";

export const RoleIdAutoSearch: React.FC<IAutoCompleteSearchesProps> = (
  props,
) => {
  const { value, onSelect } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<ISelectOption[]>([]);
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    fetchSuggestion(value);
  }, []);

  const fetchSuggestion = debounce(async (value) => {
    setLoading(true);
    try {
      const res = await api.get(`security/get-roles?pageNo=1&itemPerPage=20`);
      const data: any[] = res.data.data;
      const finalData: ISelectOption[] = [];
      for (const role of data) {
        if (role.role_id) {
          finalData.push({
            label: role.role_name,
            value: role.role_uuid,
          });
        }
      }
      setSearchResults(finalData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setSearchText(value);
  };
  const getOptionLabel = (option: string | ISelectOption) => {
    if (typeof option === "string") {
      return option;
    }
    return option.label;
  };

  React.useEffect(() => {
    if (value) {
      setSearchText(value);
      fetchSuggestion(value);
    } else {
      setSearchText("");
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        options={searchResults.map((result) => ({
          label: result.label,
          value: result.value,
        }))}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={searchResults.find((option) => option.value === value) || null}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue && typeof newValue !== "string") {
            onSelect(newValue);
          } else {
            onSelect({ label: "", value: "" });
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            value={searchText}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
